var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contactform"},[_c('div',{staticClass:"container mt-6"},[_c('h2',{staticClass:"is-size-3 is-size-4-mobile mt-6"},[_vm._v(" Kontakt aufnehmen "+_vm._s(_vm.contactheadline)+" ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('div',{staticClass:"columns is-multiline has-text-left"},[_c('div',{staticClass:"coloumn column is-5"},[_c('b-image',{staticClass:"mt-2",attrs:{"src":require("@/assets/contact/" + _vm.lab + ".jpg"),"placeholder":require("@/assets/ImagePlaceholder.png"),"ratio":"16by9"}}),_c('p',{staticClass:"is-size-6 labqoute"},[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.team)+":")]),_c('br'),_vm._v(" "+_vm._s(_vm.welcomemsg)+" ")]),_c('p')],1),_c('div',{staticClass:"coloumn column is-6 is-offset-1 gapless"},[(!_vm.send)?_c('div',{staticClass:"columns is-multiline has-text-left"},[_c('div',{staticClass:"coloumn column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-white",attrs:{"label":"Name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"custom-class":"is-large"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"coloumn column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-white",attrs:{"label":"E-Mail-Adresse","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"custom-class":"is-large"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"coloumn column is-12"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-white",attrs:{"label":"Nachricht","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"textarea","rows":"6","custom-class":"is-large"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)]}}],null,true)})],1)]):_vm._e(),(!_vm.send)?_c('b-button',{attrs:{"type":"is-primary","size":"is-large","inverted":"","outlined":""},on:{"click":function($event){return passes(_vm.contact)}}},[_vm._v("absenden")]):_vm._e(),(_vm.send)?_c('div',{staticClass:"notification is-info spacetop"},[_c('strong',[_vm._v("Danke "+_vm._s(_vm.name)+"!")]),_vm._v(" Wir haben die Nachricht erhalten und werden uns melden. ")]):_vm._e(),(_vm.nosend)?_c('div',{staticClass:"notification is-info spacetop"},[_c('strong',[_vm._v("Verdammt "+_vm._s(_vm.name)+", hier klappt etwas nicht!")]),_vm._v(" DIe E-Mail konnte gerade nicht versendet werden. Probiere es bitte später noch einmal. ")]):_vm._e()],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }