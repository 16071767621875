<template>
  <div class="contactform">
    <div class="container mt-6">
      <h2 class="is-size-3 is-size-4-mobile mt-6">
        Kontakt aufnehmen {{ contactheadline }}
      </h2>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns is-multiline has-text-left">
          <div class="coloumn column is-5">
            <b-image
              :src="require(`@/assets/contact/` + lab + `.jpg`)"
              :placeholder="require(`@/assets/ImagePlaceholder.png`)"
              class="mt-2"
              ratio="16by9"
            >
            </b-image>
            <p class="is-size-6 labqoute">
              <span class="has-text-weight-bold">{{ team }}:</span><br />
              {{ welcomemsg }}
            </p>
            <p></p>
          </div>
          <div class="coloumn column is-6 is-offset-1 gapless">
            <div class="columns is-multiline has-text-left" v-if="!send">
              <div class="coloumn column is-6">
                <ValidationProvider
                  rules="required"
                  name="name"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    label="Name"
                    class="has-text-white"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-input custom-class="is-large" v-model="name"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="coloumn column is-6">
                <ValidationProvider
                  rules="required|email"
                  name="email"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    label="E-Mail-Adresse"
                    class="has-text-white"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-input custom-class="is-large" v-model="email"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="coloumn column is-12">
                <ValidationProvider
                  rules="required"
                  name="message"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    label="Nachricht"
                    class="has-text-white"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-input
                      type="textarea"
                      rows="6"
                      custom-class="is-large"
                      v-model="message"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <b-button
              type="is-primary"
              size="is-large"
              inverted
              outlined
              @click="passes(contact)"
              v-if="!send"
              >absenden</b-button
            >
            <div class="notification is-info spacetop" v-if="send">
              <strong>Danke {{ name }}!</strong> Wir haben die Nachricht
              erhalten und werden uns melden.
            </div>
            <div class="notification is-info spacetop" v-if="nosend">
              <strong>Verdammt {{ name }}, hier klappt etwas nicht!</strong>
              DIe E-Mail konnte gerade nicht versendet werden. Probiere es bitte
              später noch einmal.
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script type="text/babel">
import axios from "axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Dieses Feld muss ausgefüllt werden.",
});

extend("email", {
  ...email,
  message: "Bitte trage eine gültige E-Mail-Adresse ein.",
});

export default {
  name: "Contactform",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    lab: String,
    contactheadline: String,
    team: String,
    welcomemsg: String,
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      send: false,
      nosend: false,
    };
  },
  methods: {
    contact() {
      this.sendverification();
      //console.log("I want to send!");
    },

    async sendverification() {
      try {
        const res = await axios.post(
          "/.netlify/functions/contactform",
          {
            Name: this.name,
            Email: this.email,
            Message: this.message,
            Lab: this.lab,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.send = true;
          //console.log("Mail send!");
        } else {
          //this.isLoading = false;
          this.nosend = true;
          //console.log("Could not send Mail.");
        }
      } catch (error) {
        this.nosend = true;
        //console.log("ERROR:", error);
      }
    },
  },
};
</script>

<style lang="scss">
.contactform {
  margin-top: 8rem;
}

.has-text-white label {
  color: $background;
  font-size: 1.2rem;
}
.has-text-white p.help.is-danger {
  color: $background;
}
.labqoute {
  padding-left: 1rem;
  border-left: 4px solid $background;
}
</style>
